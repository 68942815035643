import * as Yup from 'yup';
import { lazy } from 'react';
import { toast } from 'react-toastify';
import { SubmitHandler } from 'react-hook-form';

import { Urls } from '../../urls';
import { formatUrl } from '../../../utils/url';
import { Colors } from '../../../styles/colors';
import { SortDirection } from '../../../generated/types';

import { useUserContext } from '../../../contexts/UserContext';
import { useRouter } from '../../../hooks/useRouter';
import { usePublishJobPost } from '../../../graph/mutations/publishJobpost';
import { GET_JOB_POSTS, useGetJobPosts } from '../../../graph/queries/getJobPosts';

import { AlertIcon } from '../../../components/atoms/Icons';
import { H1 } from '../../../components/atoms/Typography';
import { TextInput } from '../../../components/atoms/Form/TextInput';
import { Form } from '../../../components/atoms/Form/Form';
import { useCreateJobPostFromUrl } from '../../../graph/mutations/createJobPostFromUrl';
const JobsOfferTable = lazy(() => import('./JobsOfferTable.styles'));

export const JobPosts = () => {
  const user = useUserContext();
  const [publishJobPost] = usePublishJobPost();
  const [createJobPostFromUrl] = useCreateJobPostFromUrl();

  const { navigate } = useRouter();
  const { data: { jobPosts = [] } = {}, loading: jobsLoading } = useGetJobPosts({
    variables: {
      where: { isDeleted: false },
      sort: { direction: SortDirection.Desc, field: 'createdAt' },
    },
  });

  if (user?.isAdmin === false) {
    return null;
  }

  async function handleJobPostPublish(userUuid: string, jobUuid: string) {
    try {
      if (!jobUuid) {
        throw new Error('jobPost not found');
      }

      await publishJobPost({
        variables: {
          userUuid,
          jobUuid,
          isPublished: true,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_JOB_POSTS,
            variables: {
              where: { isDeleted: false },
              sort: { direction: SortDirection.Desc, field: 'createdAt' },
            },
          },
        ],
      });
    } catch (error) {
      console.error(error);
      toast.error(
        'We apologize, but we were unable to create a Job Post at this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.'
      );
    }
  }

  async function handleJobPostUnpublish(userUuid: string, jobUuid: string) {
    try {
      if (!jobUuid) {
        throw new Error('jobPost not found');
      }

      await publishJobPost({
        variables: {
          userUuid,
          jobUuid,
          isPublished: false,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_JOB_POSTS,
            variables: {
              where: { isDeleted: false },
              sort: { direction: SortDirection.Desc, field: 'createdAt' },
            },
          },
        ],
      });
    } catch (error) {
      console.error(error);
      toast.error(
        'We apologize, but we were unable to create a Job Post at this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.'
      );
    }
  }

  async function handleEditJobPostClick(userUuid: string, jobUuid: string) {
    navigate(
      formatUrl(`${Urls.JobOfferUpdateHome}/${Urls.JobOfferUpdateStep1}`, {
        userUuid,
        jobUuid,
      }),
      {
        state: { from: `/${Urls.JobPosts}/#your_job_posts` },
      }
    );
  }

  async function handleViewJobPostClick(userUuid: string, jobUuid: string) {
    navigate(
      formatUrl(`/${Urls.JobPosts}/${Urls.JobPostView}`, {
        userUuid,
        jobUuid,
      }),
      {
        state: { from: `/${Urls.JobPosts}/#your_job_posts` },
      }
    );
  }

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    await createJobPostFromUrl({
      variables: {
        data: {
          jobScrapeUrl: data.url,
        },
      },
    });
  };

  return (
    <>
      <H1>Admin Job Posts</H1>

      <Form onSubmit={onSubmit} validationSchema={validationSchema}>
        <TextInput
          name="url"
          fullWidth
          type="text"
          label="Quick Job Post"
          required
          defaultValue="https://contracts.contractspy.co.uk/job/98252/it-architect-at-pay-uk-london-remote-8-months-contract-rate-outside-ir35/"
          noMargin={true}
        />
      </Form>

      <JobsOfferTable
        jobPosts={jobPosts}
        handleViewClick={handleViewJobPostClick}
        handleEditClick={handleEditJobPostClick}
        handleJobPostPublish={handleJobPostPublish}
        handleJobPostUnpublish={handleJobPostUnpublish}
      ></JobsOfferTable>
    </>
  );
};

const validationSchema = Yup.object().shape({
  url: Yup.string().required('Please enter website'),
});

type FormData = Yup.InferType<typeof validationSchema>;
