import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationCreateJobPostFromUrlArgs } from '../../generated/types';

export const CREATE_JOB_POST_FROM_URL = gql`
  mutation CreateJobPostFromUrl($data: JobPostFromUrlInputData!) {
    createJobPostFromUrl(data: $data) {
      uuid
    }
  }
`;

export const useCreateJobPostFromUrl = (
  options?: MutationHookOptions<Pick<Mutation, 'createJobPostFromUrl'>, MutationCreateJobPostFromUrlArgs>
) =>
  useMutation<Pick<Mutation, 'createJobPostFromUrl'>, MutationCreateJobPostFromUrlArgs>(CREATE_JOB_POST_FROM_URL, {
    ...options,
  });
